import React from "react";
import PropTypes from "prop-types";
import DetailDialog from "components/DetailDialog";
import ReactMarkdown from "react-markdown";
import IconText from "components/IconText";

import "./ServiceItem.scss";

const ServiceItem = ({ frontmatter, excerpt, rawMarkdownBody, langKey }) => {
  const [showDetail, setShowDetail] = React.useState(false);
  const handleShowDetail = React.useCallback(() => {
    setShowDetail(true);
  }, []);
  const handleHideDetail = React.useCallback(() => {
    setShowDetail(false);
  }, []);
  const { title } = frontmatter;

  return (
    <>
      {title ? <h4 className="service-item-heading">{title}</h4> : null}
      <ReactMarkdown className="text-dark" source={excerpt} />
      <a
        role="button"
        tabIndex={-1}
        data-toggle="modal"
        onClick={handleShowDetail}
        className="text-primary"
      >
        <IconText iconName="AngleRightIcon" text={langKey === "de" ? "Mehr" : "Maggiori informazioni"} />
      </a>
      <DetailDialog
        show={showDetail}
        onHide={handleHideDetail}
        imageFileName={null}
        imageAlt={null}
        header={title}
        subheader={null}
        content={rawMarkdownBody}
        extraInfo={null}
        langKey={langKey}
      />
    </>
  );
};

ServiceItem.propTypes = {
  frontmatter: PropTypes.object,
  excerpt: PropTypes.string,
  rawMarkdownBody: PropTypes.string,
  langKey: PropTypes.string,
};

ServiceItem.defaultProps = {
  frontmatter: null,
  excerpt: "",
  rawMarkdownBody: "",
  langKey: "de",
};

export default ServiceItem;
