import React from "react";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";

import { Modal, Button } from "react-bootstrap";
import Image from "components/Image";
import Icon from "./Icon";

import "./DetailDialog.scss";

const DetailDialog = ({
  onHide,
  imageFileName,
  imageAlt,
  header,
  subheader,
  content,
  extraInfo,
  langKey,
  ...restProps
}) => {
  return (
    <Modal
      {...restProps}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{header}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="mx-auto">
        <p className="item-intro text-dark">{subheader}</p>
        {imageFileName ? <Image
          className="mx-auto circle rounded-circle mb-4 detail-image"
          fileName={imageFileName}
          alt={imageAlt || header || subheader}
        /> : null}
        <ReactMarkdown source={content} />
        {extraInfo}
      </Modal.Body>
      <Modal.Footer>
        <div className="mx-auto">
          <Button variant="primary" onClick={onHide}>
            {langKey === "de" ? "Schließen" : "Chiudi"} &nbsp;
            <Icon iconName="CloseIcon" />
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

DetailDialog.propTypes = {
  onHide: PropTypes.func,
  imageFileName: PropTypes.string,
  imageAlt: PropTypes.string,
  header: PropTypes.string,
  subheader: PropTypes.string,
  content: PropTypes.string,
  extraInfo: PropTypes.any,
  langKey: PropTypes.string,
};

DetailDialog.defaultProps = {
  onHide: null,
  imageFileName: "",
  imageAlt: null,
  header: "",
  subheader: "",
  content: "",
  extraInfo: null,
  langKey: "de",
};

export default DetailDialog;
