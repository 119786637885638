import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";
import SectionHeader from "components/SectionHeader";
import PageSection from "components/PageSection";
import Image from "components/Image";

import "./About.scss";

const About = ({ className, frontmatter, langKey }) => {
  if (!frontmatter) {
    return null;
  }

  const {
    anchor,
    header: rootHeader,
    subheader: rootSubHeader,
    texts,
    imageFileName,
    imageAlt,
  } = frontmatter;

  return (
    <PageSection className={className} id={anchor}>
      <Row>
        <SectionHeader header={rootHeader} subheader={rootSubHeader} />
      </Row>
      <Row>
        <Col lg={12} className="mx-auto text-center">
          <p className="large text-dark">{texts[0]}</p>
        </Col>
      </Row>
      {/* <Row>
        <Col lg={6} className="mx-auto text-center">
          <Image
            className="image team-image"
            fileName={imageFileName}
            alt={imageAlt || rootHeader || rootSubHeader}
          />
        </Col>
      </Row> */}
      {texts.slice(1).map((text) => (
        <Row key={text}>
          <Col lg={12} className="mx-auto text-center">
            <p className="large text-dark">{text}</p>
          </Col>
        </Row>
      ))}
    </PageSection>
  );
};

About.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
  langKey: PropTypes.string,
};

About.defaultProps = {
  className: null,
  frontmatter: null,
  langKey: null,
};

export default About;
