import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";

import { Container, Row, Col } from "react-bootstrap";
import FooterItem from "components/FooterItem";

const Footer = ({ frontmatter, langKey }) => {
  if (!frontmatter) {
    return null;
  }

  const { copyright } = frontmatter;

  return (
    <footer className="footer py-3">
      <Container>
        <Row className="align-items-center text-center">
          <Col lg={6} className="text-lg-left">
            {copyright}
          </Col>
          <Col lg={6} className="text-lg-right">
            <StaticQuery
              query={graphql`
                query {
                  allMarkdownRemark(
                    filter: { fields: { directoryName: { eq: "footer" } } }
                  ) {
                    nodes {
                      frontmatter {
                        title
                      }
                      fields {
                        langKey
                      }
                      rawMarkdownBody
                    }
                  }
                }
              `}
              render={(data) => {
                // get only services of desired language
                const footerItems = data.allMarkdownRemark.nodes.filter(
                  (n) => n.fields.langKey === langKey,
                );
                return footerItems.map((footerItem) =>
                  (<FooterItem
                    key={footerItem.frontmatter.title}
                    frontmatter={footerItem.frontmatter}
                    rawMarkdownBody={footerItem.rawMarkdownBody}
                    langKey={footerItem.fields.langKey}
                  />));
              }}
            />
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

Footer.propTypes = {
  frontmatter: PropTypes.object,
  langKey: PropTypes.string,
};

Footer.defaultProps = {
  frontmatter: null,
  langKey: "de",
};

export default Footer;
