import React from "react";
import PropTypes from "prop-types";
import DetailDialog from "components/DetailDialog";
import IconText from "components/IconText";

import Image from "components/Image";

import "./TeamMember.scss";

const TeamMember = ({ imageFileName, imageAlt, header, subheader, rawMarkdownBody, langKey }) => {
  const [showDetail, setShowDetail] = React.useState(false);
  const handleShowDetail = React.useCallback(() => {
    setShowDetail(true);
  }, []);
  const handleHideDetail = React.useCallback(() => {
    setShowDetail(false);
  }, []);

  return (
    <>
      <div className="team-member">
        <Image
          className="mx-auto circle rounded-circle"
          fileName={imageFileName}
          alt={imageAlt || header || subheader}
        />
        <h4>{header}</h4>
        <p className="text-dark">{subheader}</p>
        <a
          role="button"
          tabIndex={-1}
          data-toggle="modal"
          onClick={handleShowDetail}
          className="text-primary"
        >
          <IconText iconName="AngleRightIcon" text={langKey === "de" ? "Mehr" : "Maggiori informazioni"} />
        </a>
      </div>
      <DetailDialog
        show={showDetail}
        onHide={handleHideDetail}
        imageFileName={imageFileName}
        imageAlt={imageAlt || header || subheader}
        header={header}
        subheader={subheader}
        content={rawMarkdownBody}
        extraInfo={null}
        langKey={langKey}
      />
    </>
  );
};

TeamMember.propTypes = {
  imageFileName: PropTypes.string.isRequired,
  imageAlt: PropTypes.string,
  header: PropTypes.string,
  subheader: PropTypes.string,
  rawMarkdownBody: PropTypes.string,
  langKey: PropTypes.string,
};

TeamMember.defaultProps = {
  imageAlt: null,
  header: "",
  subheader: "",
  rawMarkdownBody: "",
  langKey: "de",
};

export default TeamMember;
