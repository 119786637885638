import React from "react";
import PropTypes from "prop-types";
import { Map, TileLayer, Marker, Popup } from 'react-leaflet'
import { Row, Col } from "react-bootstrap";
import Icon from "components/Icon";
import PageSection from "components/PageSection";
import ReactTooltip from "react-tooltip";

const Contact = ({ className, frontmatter, langKey }) => {

  const location = {
    lat: 46.67342,
    lng: 11.15985,
    zoom: 16,
  }

  if (!frontmatter) {
    return null;
  }

  const { anchor, header, subheader, telephone, fax, email, address, tooltips } = frontmatter;
  const position = [location.lat, location.lng]

  return (
    <PageSection className={className} id={anchor}>
      <Row className="justify-content-center">
        <Col lg={12} className="text-center">
          <h2 className="mt-0">{header}</h2>
          <p className="text-dark mb-5">{subheader}</p>
        </Col>
      </Row>
      <Row>
        <Col lg={4} className="ml-auto text-center mb-5">
          <Icon iconName="PhoneIcon" size="3x" className="text-primary mb-3" data-tip={tooltips.phone} />
          <a className="d-block" href={`tel:${telephone}`}>
            {telephone}
          </a>
        </Col>
        <Col lg={4} className="ml-auto text-center mb-5">
          <Icon iconName="FaxIcon" size="3x" className="text-primary mb-3" data-tip={tooltips.fax} />
          <a className="d-block">{fax}</a>
        </Col>
        <Col lg={4} className="mr-auto text-center mb-5">
          <Icon iconName="EnvelopIcon" size="3x" className="text-primary mb-3" data-tip={tooltips.email} />
          <a className="d-block" href={`mailto:${email}`}>
            {email}
          </a>
        </Col>
      </Row>
      <Row>
        <Col lg={12} className="mr-auto text-center mb-5">
          <Icon iconName="MapMarkerIcon" size="3x" className="text-primary mb-3" data-tip={tooltips.address} />
          <div className="d-block">
            {address.map((line) => (
              <span key={line}>
                {line}
                <br />
              </span>
            ))}
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={12} className="mr-auto text-center">
          {
            typeof window !== 'undefined' ?
              <Map lg={6} center={position} zoom={location.zoom} style={{ height: "300px" }}>
                <TileLayer
                  attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker position={position}>
                  <Popup>
                    <div className="text-center">
                      <strong>Gapp Wielander Laurenza</strong><br />
                      Anwaltssozietät - Studio Legale Associato
                    </div>
                  </Popup>
                </Marker>
              </Map>
              : null
          }
        </Col>
      </Row>
      <ReactTooltip place="left" effect="solid" />
    </PageSection>
  );
};

Contact.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
  langKey: PropTypes.string,
};

Contact.defaultProps = {
  className: null,
  frontmatter: null,
  langKey: null,
};

export default Contact;
