import React from "react";
import PropTypes from "prop-types";
import DetailDialog from "components/DetailDialog";

import "./FooterItem.scss";

const FooterItem = ({ frontmatter, rawMarkdownBody, langKey }) => {
    const [showDetail, setShowDetail] = React.useState(false);
    const handleShowDetail = React.useCallback(() => {
        setShowDetail(true);
    }, []);
    const handleHideDetail = React.useCallback(() => {
        setShowDetail(false);
    }, []);
    const { title } = frontmatter;

    return (
        <>
            <a
                role="button"
                tabIndex={-1}
                className="mr-3 footer-link"
                data-toggle="modal"
                onClick={handleShowDetail}>
                {title}
            </a>
            <DetailDialog
                show={showDetail}
                onHide={handleHideDetail}
                imageFileName={null}
                imageAlt={null}
                header={title}
                subheader={null}
                content={rawMarkdownBody}
                extraInfo={null}
                langKey={langKey}
            />
        </>
    );
};

FooterItem.propTypes = {
    frontmatter: PropTypes.object,
    rawMarkdownBody: PropTypes.string,
    langKey: PropTypes.string,
};

FooterItem.defaultProps = {
    frontmatter: null,
    rawMarkdownBody: "",
    langKey: "de",
};

export default FooterItem;
