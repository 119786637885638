import React from "react";
import PropTypes from "prop-types";

import { Row, Col } from "react-bootstrap";
import TeamMember from "components/TeamMember";
import SectionHeader from "components/SectionHeader";
import PageSection from "components/PageSection";
import "./Team.scss";
import { StaticQuery, graphql } from "gatsby";

const Team = ({ className, frontmatter, langKey }) => {
  if (!frontmatter) {
    return null;
  }

  const {
    anchor,
    header: rootHeader,
    subheader: rootSubHeader,
    content: rootContent,
  } = frontmatter;

  return (
    <PageSection className={className} id={anchor}>
      <Row>
        <SectionHeader header={rootHeader} subheader={rootSubHeader} />
      </Row>
      <Row>
        <StaticQuery
          query={graphql`
            query {
              allMarkdownRemark(
                filter: { fields: { directoryName: { eq: "team" } } }
                sort: { order: DESC, fields: [fields___fileName] }
              ) {
                nodes {
                  frontmatter {
                    header
                    subheader
                    imageFileName
                    imageAlt
                  }
                  fields {
                    langKey
                  }
                  rawMarkdownBody
                }
              }
            }
          `}
          render={(data) => {
            // get only services of desired language
            const teamMembers = data.allMarkdownRemark.nodes.filter(
              (n) => n.fields.langKey === langKey,
            );
            return teamMembers.map((teamMember) => (
              <Col sm={4} key={teamMember.frontmatter.header}>
                <TeamMember
                  imageFileName={teamMember.frontmatter.imageFileName}
                  imageAlt={teamMember.frontmatter.imageAlt}
                  header={teamMember.frontmatter.header}
                  subheader={teamMember.frontmatter.subheader}
                  rawMarkdownBody={teamMember.rawMarkdownBody}
                  langKey={langKey}
                />
              </Col>
            ));
          }}
        />
      </Row>
      <Row>
        <Col lg={8} className="mx-auto text-center">
          <p className="large text-dark">{rootContent}</p>
        </Col>
      </Row>
    </PageSection>
  );
};

Team.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
  langKey: PropTypes.string,
};

Team.defaultProps = {
  className: null,
  frontmatter: null,
  langKey: null,
};

export default Team;
