import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";

import { Row, Col } from "react-bootstrap";

import ServiceItem from "components/ServiceItem";
import SectionHeader from "components/SectionHeader";
import PageSection from "components/PageSection";

const Services = ({ className, frontmatter, langKey }) => {
  if (!frontmatter) {
    return null;
  }

  const { anchor, header: rootHeader, subheader: rootSubHeader } = frontmatter;

  return (
    <PageSection className={className} id={anchor}>
      <Row>
        <SectionHeader header={rootHeader} subheader={rootSubHeader} />
      </Row>
      <Row className="text-center">
        <StaticQuery
          query={graphql`
            query {
              allMarkdownRemark(
                filter: { fields: { directoryName: { eq: "services" } } }
                sort: { order: DESC, fields: [fields___fileName] }
              ) {
                nodes {
                  frontmatter {
                    title
                  }
                  fields {
                    langKey
                  }
                  excerpt(format: MARKDOWN)
                  rawMarkdownBody
                }
              }
            }
          `}
          render={(data) => {
            // get only services of desired language
            const services = data.allMarkdownRemark.nodes.filter(
              (n) => n.fields.langKey === langKey,
            );

            return services.map((service) => (
              <Col md={6} className="mb-4" key={service.frontmatter.title}>
                <ServiceItem {...service} langKey={langKey} />
              </Col>
            ));
          }}
        />
      </Row>
    </PageSection>
  );
};

Services.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
  langKey: PropTypes.string,
};

Services.defaultProps = {
  className: null,
  frontmatter: null,
  langKey: "",
};

export default Services;
